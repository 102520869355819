import { Col, Collapse, Grid, Row, Typography } from "antd";

import faqs from "../../constants/faqs";
import "./static/indexSectionTwo.css";

const { Title } = Typography;
const { Panel } = Collapse;

export default function Page2() {
    const screens = Grid.useBreakpoint();
    const isMobile = screens.xs || screens.sm || screens.md;
    const isLarge = screens.lg || screens.xl || screens.xxl;
    return (
        <div
            style={{
                backgroundColor: "#f8fafe",
                paddingTop: "4rem",
                paddingBottom: "2rem",
            }}
        >
            <Title
                style={{
                    textAlign: "center",
                    marginBottom: "30px",
                    fontSize: "2.2rem",
                }}
            >
                Questions? Answers.
            </Title>
            <span key="line" className="separator" />
            <Row justify={isMobile && !isLarge ? "center" : "start"}>
                <Col
                    lg={{ span: 18, offset: 3 }}
                    md={{ span: 20, offset: 2 }}
                    sm={{ span: 22, offset: 0 }}
                    xs={{ span: 22, offset: 0 }}
                >
                    <Collapse>
                        {faqs.map((faq, index) => (
                            <Panel
                                header={
                                    <Title
                                        level={4}
                                        style={{ marginBottom: 0 }}
                                    >
                                        {faq.question}
                                    </Title>
                                }
                                key={faq.key}
                            >
                                <p
                                    style={{
                                        fontSize: "1.1rem",
                                        marginBottom: 0,
                                        padding: 6,
                                    }}
                                >
                                    {faq.answer}
                                </p>
                            </Panel>
                        ))}
                    </Collapse>
                </Col>
            </Row>
        </div>
    );
}

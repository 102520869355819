import { Button, Col, ConfigProvider, Grid, Row, Typography } from "antd";
import { createStyles } from "antd-style";
import { motion } from "framer-motion";
import styled, { keyframes } from "styled-components";

import { DownloadOutlined } from "@ant-design/icons";

const { Title, Paragraph } = Typography;

const StatsBox = styled.div({
    paddingTop: 10,
    paddingLeft: 10,
});

const gradientAnimation = keyframes`
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
`;

const AnimatedRow = styled(Row)`
    background: linear-gradient(270deg, #00be8e, #0072ff);
    background-size: 200% 200%;
    animation: ${gradientAnimation} 6s ease infinite;
    display: flex;
    justifycontent: center;
    flex: 1;
    min-height: calc(max(100svh, 600px) + 30px);
`;

export default function Download() {
    const sizes = Grid.useBreakpoint();
    const isMobile =
        sizes.xs || (sizes.sm && !sizes.md) || window.innerWidth < 768;
    const isSmOrBelow =
        ((sizes.xs || sizes.sm) && !sizes.md) || window.innerWidth < 768;

    const goToInstallApp = () => {
        window.open(process.env.REACT_APP_STORE_URL);
    };

    // Style for the gradient button
    //To change gradient, use background. To change the basecolor of the button, use the background-color in its styles.
    const useStyle = createStyles(({ prefixCls, css }) => ({
        linearGradientButton: css`
            &.${prefixCls}-btn-primary:not([disabled]):not(
                    .${prefixCls}-btn-dangerous
                ) {
                > span {
                    position: relative;
                }

                &::before {
                    content: "";
                    background: linear-gradient(
                        90deg,
                        hsla(358, 26%, 79%, 1) 0%,
                        hsla(273, 60%, 73%, 1) 50%,
                        hsla(356, 53%, 57%, 1) 100%
                    );
                    position: absolute;
                    inset: -1px;
                    opacity: 1;
                    transition: all 0.3s;
                    border-radius: inherit;
                }

                &:hover::before {
                    opacity: 0;
                }
            }
        `,
    }));

    const { styles } = useStyle();

    return (
        <AnimatedRow>
            <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                xxl={24}
                style={{
                    ...(isMobile
                        ? { paddingLeft: "0rem", paddingRight: ".25rem" }
                        : {}),
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    flex: 1,
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        textAlign: "center",
                    }}
                >
                    <motion.div
                        initial={{
                            scale: 2,
                        }}
                        animate={{
                            scale: 0.5,
                        }}
                        transition={{ duration: 1, ease: "easeOut" }}
                    >
                        <Title
                            style={{
                                marginBottom: 0,
                                fontSize: isMobile ? "4rem" : "6.5rem",
                                fontWeight: "bolder",
                                color: "white",
                            }}
                        >
                            Open Insure
                        </Title>
                    </motion.div>
                    <div
                        style={{
                            overflow: "hidden",
                            // height: isMobile ? "6rem" : "7rem",
                        }}
                    >
                        <motion.div
                            initial={{
                                y: isMobile ? 210 : 120,
                            }}
                            animate={{
                                y: 0,
                            }}
                            transition={{
                                duration: 0.5,
                                delay: 1,
                                ease: "easeOut",
                            }}
                        >
                            <Title
                                style={{
                                    fontSize: isMobile ? "5.5em" : "7rem",
                                    marginTop: 0,
                                    marginBottom: ".75rem",
                                    fontWeight: "normal",
                                    color: "white",
                                    fontFamily:
                                        "'Helvetica Neue', Helvetica, Arial, sans-serif",
                                }}
                            >
                                Insurance
                            </Title>
                        </motion.div>
                    </div>
                    <div
                        style={{
                            overflow: "hidden",
                            marginBottom: ".75rem",
                        }}
                    >
                        <motion.div
                            initial={{
                                y: isMobile ? 210 : 120,
                            }}
                            animate={{
                                y: 0,
                            }}
                            transition={{
                                duration: 0.5,
                                delay: 1.5,
                                ease: "easeOut",
                            }}
                        >
                            <Title
                                style={{
                                    fontSize: isMobile ? "5.5rem" : "7rem",
                                    marginTop: 0,
                                    marginBottom: ".75rem",
                                    fontWeight: "bolder",
                                    color: "white",
                                    fontFamily:
                                        "'Helvetica Neue', Helvetica, Arial, sans-serif",
                                }}
                            >
                                should be free.
                            </Title>
                        </motion.div>
                    </div>
                </div>
                <motion.div
                    initial={{
                        opacity: 0,
                    }}
                    animate={{
                        opacity: 1,
                    }}
                    transition={{
                        duration: 0.5,
                        delay: 2.5,
                        ease: "easeIn",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                        }}
                    >
                        <Paragraph
                            style={{
                                flexDirection: "column",
                                fontSize: isMobile ? "1rem" : "1.45rem",
                                fontWeight: 400,
                                // marginTop: isMobile ? "1rem" : ".5rem",
                                marginBottom: 0,
                                textAlign: "center",
                                color: "black",
                            }}
                        >
                            Open Insure makes it easy for you to{" "}
                            <strong>self-insure</strong> <br />
                            your personal property on a modern web platform.{" "}
                            <br />
                            Try it now.
                        </Paragraph>
                    </div>
                    <ConfigProvider
                        button={{
                            className: styles.linearGradientButton,
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Button
                                size="large"
                                type="primary"
                                icon={<DownloadOutlined />}
                                style={{
                                    backgroundColor: "#C39ADB",
                                    marginTop: "1rem",
                                    color: "black",
                                    fontWeight: "bold",
                                }}
                                shape="round"
                                onClick={goToInstallApp}
                            >
                                Install App
                            </Button>
                        </div>
                    </ConfigProvider>
                </motion.div>
            </Col>
        </AnimatedRow>
    );
}
